.chatbotIcon img {
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 150px;
  width: auto;
  filter: drop-shadow(0px 0px 5px var(--c_blue));
  cursor: pointer;
  animation: zoom-in-zoom-out 1s 1 ease-in-out;
  transition: 0.4s;
}
.chatbotIcon img:hover {
  filter: drop-shadow(0px 0px 10px var(--c_blue));
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0, 0);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.chatbotIconMessage {
  position: fixed;
  bottom: 165px;
  right: 65px;
  background-color: var(--c_blue);
  color: white;
  padding: 5px 10px;
  min-width: 90px;
  text-align: center;
  border-radius: 8px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  animation: zoom-in-zoom-out 2s 1 ease-in-out;
  white-space: pre-wrap;
}
.chatbotIconMessage:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -18px;
  border: 10px solid;
  border-color: var(--c_blue) var(--c_blue) transparent transparent;
  border-radius: 5px;
}
/* INBOX */
.inboxContainer {
  z-index: 9999;
  position: fixed;
  bottom: 10px;
  right: 10px;
  min-width: 330px;
  max-width: 330px;
  background-color: rgba(9, 12, 27, 0.9);
  border-radius: 20px;
  animation: zoom-in-zoom-out 1s 1 ease-in-out;
}
.inboxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0px 15px;
  overflow: hidden;
  background: linear-gradient(var(--c_blue), var(--c_blue_dark));
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.leftContainer {
  display: flex;
  align-items: center;
}
.leftContainer img {
  width: 35px;
  height: 35px;
}
.titleContainer {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 5px;
}
.titleContainer p {
  color: white;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}
.onlineIndicator {
  font-size: 14px;
  color: greenyellow;
  margin-left: 5px;
}
.closeButton {
  font-size: 30px;
  cursor: pointer;
}
.messagesContainer {
  background-color: rgb(9, 12, 27);
  padding-top: 10px;
  height: 320px;
  overflow: auto;
  clear: both;
}
.messagesContainer::-webkit-scrollbar {
  width: 2px;
}
.messagesContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--c_background);
}
.messagesContainer::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, var(--c_blue), var(--c_blue_dark));
  border-radius: 25%;
}
.bot {
  background: linear-gradient(120deg, #484948, #626362);
  color: white;
  line-height: 1.5em;
  display: table;
  padding: 10px 10px;
  margin-bottom: 15px; /*Zbog reakcije 15px, bez 10px*/
  margin-left: 10px;
  font-size: 14px;
  font-family: "Roboto", Arial, sans-serif;
  max-width: 70%;
  width: auto;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  word-wrap: break-word;
  white-space: pre-wrap;
  clear: both;
  animation: floatup 0.5s forwards;
}
.user {
  float: right;
  background: linear-gradient(120deg, var(--c_blue), var(--c_blue_dark));
  color: white;
  line-height: 1.5em;
  padding: 10px 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-family: "Roboto", Arial, sans-serif;
  max-width: 70%;
  width: auto;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  word-wrap: break-word;
  white-space: pre-wrap;
  clear: both;
  animation: floatup 0.5s forwards;
}
.loadingBotMessage {
  margin: 0px !important;
}
.loadingBotMessageDot {
  background-color: white !important;
  height: 10px !important;
  width: 10px !important;
}
.reactionsContainer {
  position: absolute;
  right: 5px;
  display: flex;
  gap: 10px;
  color: #e6e6e6;
  font-size: 19px;
}
.reactionButton {
  cursor: pointer;
}
.reactionActive {
  color: var(--c_blue);
  animation: react 0.5s forwards;
}
@keyframes floatup {
  from {
    transform: translateY(14px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes react {
  from {
    font-size: 30px;
    transform: translateY(15px);
    opacity: 0;
  }
  to {
    font-size: 19px;
    transform: translateY(0px);
    opacity: 1;
  }
}
.inputContainer {
  background-color: rgb(9, 12, 27);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  gap: 8px;
}
.inputContainer input {
  border: none;
  color: aliceblue;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 18px;
  padding: 8px;
  border-radius: 11px;
  width: 100%;
  resize: none;
}
.inputContainer input:focus {
  outline: 1px solid rgba(255, 255, 255, 0.5);
}
.inputError {
  border: 1px solid var(--c_error) !important;
}
.inputError:focus {
  outline: 1px solid var(--c_error) !important;
}
.sendButton {
  color: var(--c_blue);
  font-size: 24px;
  cursor: pointer;
  transition: 0.3s;
}
.sendButton:hover {
  color: aliceblue;
}
@media (max-width: 640px) {
  .inboxContainer {
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 0px !important;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-width: 100%;
  }
  .messagesContainer {
    height: 100%;
  }
}
