.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(236, 236, 236);
}
.container {
  z-index: 9999999999 !important;
  background-color: var(--c_background);
  width: 30%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}
.titleContainer {
  z-index: 9999999999 !important;
  background-color: var(--c_error);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  padding: 7px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.messageContainer p {
  color: rgb(236, 236, 236);
}
.closeIcon {
  font-size: 28px;
  cursor: pointer;
}
.messageContainer {
  color: black;
  min-height: 100px;
  margin: 20px 15px;
  font-family: Arial, Helvetica, sans-serif;
}
.message {
  display: flex;
  align-items: center;
}
.icon {
  color: var(--c_error);
  font-size: 70px;
  margin-right: 10px;
}
.buttonsContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  width: auto;
}
.button {
  background-color: var(--c_error) !important;
  border-radius: 5px !important;
  margin-left: 10px !important;
  text-transform: uppercase;
  font-size: 16px !important;
  width: 100px !important;
  min-width: fit-content !important;
}
.no {
  background-color: transparent !important;
  border: 2px solid rgb(236, 236, 236) !important;
  color: rgb(236, 236, 236) !important;
}
.yes {
  color: white !important;
}
@media (max-width: 1500px) {
  .container {
    width: 40%;
  }
}
@media (max-width: 1110px) {
  .container {
    width: 50%;
  }
}
@media (max-width: 800px) {
  .container {
    width: 70%;
  }
}
@media (max-width: 500px) {
  .container {
    width: 90%;
  }
}
.warningBg {
  background-color: var(--c_error) !important;
}
.warning {
  color: var(--c_error) !important;
}
.successBg {
  background-color: var(--c_green) !important;
}
.success {
  color: var(--c_green) !important;
}
