.header {
  z-index: 99;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 153, 255, 0.3);
  background: linear-gradient(rgb(7, 109, 177), rgb(9, 51, 114));
  padding: 8px 10px;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex p {
  padding: 0 10px;
}
.home {
  background-color: var(--c_background);
  color: aliceblue;
  padding: 5px;
  border-radius: 7px;
  text-transform: uppercase;
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
}
.home p {
  font-size: 18px;
}
.home:hover {
  color: var(--c_blue);
}
.homeIcon {
  margin-left: 10px;
  font-size: 24px !important;
}
.icon {
  font-size: 24px !important;
  transition: 0.2s;
  cursor: pointer;
}
.icon:hover {
  color: var(--c_blue);
}
.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.disabled:hover {
  color: white;
}
