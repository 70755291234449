.container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerText {
  text-align: center;
}
.icon {
  font-size: 130px;
}
.container h1 {
  color: var(--c_blue);
  font-size: 100px;
  font-weight: 800;
  line-height: 100px;
}
.container h2 {
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
}
.containerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--c_blue);
  border-radius: 1rem;
  max-width: max-content;
  margin: 0 auto;
  margin-top: 10px;
  padding: 5px 15px;
  cursor: pointer;
  gap: 8px;
  text-decoration: none;
  color: aliceblue;
}
.containerButton p {
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
}
.iconBack {
  font-size: 45px;
}
