.iconContainer {
  text-align: center;
  margin-top: 20px;
}
.iconContainer h1 {
  text-transform: uppercase;
  padding: 0 2px;
}
.addIcon {
  font-size: 150px;
  transition: 0.3s;
  cursor: pointer;
}
.addIcon:hover {
  color: var(--c_blue);
}
@media (max-width: 470px) {
  .iconContainer h1 {
    font-size: 28px;
  }
}
