:root {
  --c_blue: rgb(0, 153, 255);
  --c_blue_dark: rgb(9, 90, 211);
  --c_background: rgb(16, 19, 36);
  --c_red: rgb(243, 99, 94);
  --c_error: rgb(226, 61, 55);
  --c_error_rgb: 226, 61, 55;
  --c_gray: #7f7f7f;
  --c_green: rgb(68, 131, 68);
}
