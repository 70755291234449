.container {
  padding: 15px;
}
.title {
  text-align: center;
  color: aliceblue;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 5px 15px;
}
.content {
  resize: none;
  color: aliceblue;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif; /*Work Sans*/
  word-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
  height: fit-content !important;
  min-height: 100px;
  margin-top: 10px;
  padding: 15px;
}
.title:focus,
.content:focus {
  border: none;
  outline: 2px solid var(--c_blue);
  font-style: oblique;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 100;
}
.buttonsFlex {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 15px;
}
.delete {
  background-color: var(--c_gray) !important;
}
.inputError {
  border: none !important;
  outline: 2px solid var(--c_error) !important;
  background-color: rgb(var(--c_error_rgb), 0.3);
}
.phraseHeader {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: none;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 2px;
  margin-top: 20px;
}
.phraseContainer {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif; /*Work Sans*/
  word-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
  height: fit-content !important;
  padding: 15px;
}
.legend {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
}
.legend span {
  width: 100%;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-top: none;
  padding: 3px 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.phraseContainer p strong {
  color: var(--c_blue);
}
b {
  font-weight: normal !important;
}
