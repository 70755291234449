@import url("./styles/colors.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--c_background);
  color: aliceblue;
}

* {
  margin: 0;
  box-sizing: border-box;
}
.subject {
  color: rgb(211, 204, 102) !important;
}
.verb {
  color: rgb(202, 125, 120) !important;
}
.root {
  color: rgb(72, 192, 136) !important;
}
.error {
  color: var(--c_red);
}
