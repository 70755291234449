.loaderContainter {
  z-index: 9999;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.loader {
  border: 20px solid #eaf0f6;
  border-radius: 50%;
  border-top: 20px solid var(--c_blue);
  width: 150px;
  height: 150px;
  animation: spinner 2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.alertContainer {
  position: fixed;
  top: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.alertContainer p {
  background-color: rgba(189, 153, 55, 0.8);
  padding: 5px 10px;
  width: 90%;
  text-align: center;
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  white-space: pre-wrap;
}
