.button {
  background-color: var(--c_blue);
  color: black;
  padding: 7px 10px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
